import React from "react";
import "./Appheader.css";

interface Props {
  label: string;
}

const Appheader: React.FC<Props> = ({ label }) => {
  return (
    <div className='appheadercnt'>
      <h2>{label}</h2>
    </div>
  );
};

export default Appheader;
