import React, { useState, ReactNode } from "react";
import "./CurrencyCard.css";
import cadflag from "../../assets/cadflag.svg";
import gbpflag from "../../assets/gbpflag.svg";
import usdflag from "../../assets/usdflag.svg";
import Appheaderone from "../Appheaderone/Appheaderone";
import { arrowdownicon } from "../../konstant";
import CurrencyFlag from 'react-currency-flags';
 import ReactCountryFlag from "react-country-flag"


function CurrencyCard(props: any) {
  
  const [currency, setCurrency] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const currencylist = [
    {
      name: "United Kingdom",
      countrycode: "GBP",
      flag: gbpflag,
    },
    {
      name: "United States",
      countrycode: "USD",
      flag: usdflag,
    },
    {
      name: "Canada",
      countrycode: "CAD",
      flag: cadflag,
    },
  ];
  return (
    <>
      <div className='curencycardcnt' onClick={() => {
        setCurrency(true);
      }}>
        {/* {currencylist.slice(0, 1).map((crlst, index) => (
          
        ))} */}
        {
          props.value ?
          <div className='cryimage'>
            <CurrencyFlag currency={props.value.toUpperCase()} size="sm" />
            {props.value.toUpperCase()} {arrowdownicon}
          </div>
          :

          <div className='cryimage'>
            No wallet
          </div>
        }
      </div>
      {currency && props.currencies && (
        <div className='currencycardlist'>
          <Appheaderone
            handleClick={() => {
              setCurrency(false)
            }}
            label='Select Account'
          />

          <div className='currencycardlistcnt'>
            {props.currencies.map((crlst: any, index: number) => (
              <div 
                // href="javascript:;" 
                onClick={() => {
                  props.onChange(crlst.label)
                  setCurrency(false);
                }}
                className='crclstcnt' key={index}>
                <div className='crclstcntleft'>
                  <CurrencyFlag currency={crlst?.label?.toUpperCase()} size="sm" />
                  <p className='crclsname'>{crlst.label}</p>
                </div>
                <p className='crclstcntright'>{crlst.label}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default CurrencyCard;
