// import NProgress from "nprogress";
const NProgress = require('nprogress')

export default function setupAxios(axios: any, store: any) {
  axios.interceptors.request.use(
    (config: any) => {
      const {
        // auth: {accessToken},
        auth: { authToken },
      } = store.getState()
      NProgress.start();
      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )

  axios.interceptors.response.use(function (response: any) {
    NProgress.done();
      return response;
  }, function (error: any) {
      console.error(error)
      NProgress.done();
      return Promise.reject(error);
  });
}
