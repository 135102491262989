import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Bills.css";
import Applayout from "../../../layouts/Applayout/Applayout";
import { arrowdownlineicon } from "../../../konstant";
import ActiveSubdetails from "../../../components/ActiveSubdetails/ActiveSubdetails";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../home/_redux/home/Actions";
import * as functions from "../../globals/functions";
import moment from "moment";

function Bills() {
  const dispatch = useDispatch<any>();
  const location = useLocation();
  let navigate = useNavigate();
  const handledeleteclick = () => {};

  const [transs, set_trans] = useState<any>(null);


  const itemData = [
    {
      name: 'airtime',
      icon: 'share-2'
    },
    {
        name: 'Data Bundle',
        value: 'data_bundle',
    },
    {
        name: 'power',
        icon: 'power'
   
    },
    {
        name: 'internet',
        icon: 'wifi'
    },
    {
        name: 'toll',
        icon: 'map'
    },
    {
        name: 'cable',
        icon: 'tablet'
    }
];


useEffect(() => {//&limit=3
    dispatch(actions.fetchData('trans', '?type=bill')).then((resss: any) => {
        if (resss && resss.success) {
            set_trans(resss.success)
        }
    });
}, [])

function getD(date: any) {
  if (moment().format('L') !== moment(date).format('L')) {
      return `${moment(date).format('ll')} • ${moment(date).format('LT')}`;
  } else {
      return `${moment(date).format('LT')}`;
  }
}


  return (
    <>
      <Applayout linkaddress={location.pathname} />
      <h1 className='billsheader'>Pay Bills</h1>
      <div className='billitems'>
        {
          itemData.map((ele: any, i: number) => {
            return (
              <div
                className='billitem'
                onClick={() => navigate("/bills/pay", {
                    state: {
                        data: ele
                    }
                })}
              >
              <p className='billitemname' style={{
                textTransform: 'capitalize'
              }}>{ele.name}</p>
            </div>
            )
          })
        }
   
      </div>

      <div className='billsactive'>
        <h3>Active Subscriptions</h3>
        <div
          className='billsactiveright'
          // onClick={() => navigate("/bills/payment-method")}
        >
          <label>View All</label>
          {arrowdownlineicon}
        </div>
      </div>

      <div className='billsactivesubbody'>
        {
          transs && transs.map((ele: any, i: number) => {
            return (
              <ActiveSubdetails
                key = {i}
                status = {ele.status}
                handleClick={handledeleteclick}
                subname={`${ele?.remark || 'Bill Payment'}`}
                date={`${getD(ele.createdAt)}`}
                amount={`${functions.symbol(ele.currency)} ${functions.number_format(ele.amount)}`}
                handleView={() => console.log("/bills/active-bills")}
              />
            )
          })
        }
       
      </div>
    </>
  );
}

export default Bills;
