import React, {useEffect, useState} from "react";
// import "./Transaction.css";
import { useNavigate } from "react-router-dom";
import InputField from "../../../../components/InputField/InputField";
import SelectField from "../../../../components/InputField/SelectField";
import AuthBtn from "../../../../components/AuthBtn/AuthBtn";

import { shallowEqual, useDispatch, useSelector } from "react-redux";

function US(props: any) {
  let navigate = useNavigate();
    // var t = props.t;        
    const account_type_data = [
        {
            name: 'Company',
            value: 'company',
        },
        {
            name: 'Individual',
            value: 'individual',
        }
    ];

 

    const {  listLoading, actionsLoading, banks, } = useSelector(
        (state: any) => ({ 
            banks: state.home.banks,
            listLoading: state.home.listLoading,
            actionsLoading: state.home.actionsLoading
        }),
        shallowEqual
    );


  return (
    <div>

        <SelectField 
            options = {account_type_data}
            value = {props.account_type}
            onChange={(e) => props.set_account_type(e.target.value)}
            type='text' label='Account Holder Type'  placeholder="Select account type"/>

        <InputField
            value = {props.account_number}
            onChange = {(e) => props.set_account_number(e.target.value)}
            label='Account Number'  type='number'  />


        <InputField
            value = {props.routing_number}
            onChange = {(e) => props.set_routing_number(e.target.value)}
            label='Routing Number'  type='number'  />



        <InputField type='text' 
            onChange = {(e:any) => props.set_info(e.target.value)}
            value = {props.info}
            label='Transaction Remarks' />
        


        <AuthBtn 
            label='Continue' 
            loading = {actionsLoading || listLoading || props.loading_3}
            disabled = {
                props.account_type === '' || 
                props.account_number === '' 
            || props.routing_number === ''
            }
            handleClick={() => props.proceed()}
        />
 
    </div>
  );
}

export default US;
