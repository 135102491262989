import axios from "axios";
const URL = process.env.REACT_APP_API_URL || 'api'

export const CUSTOMERS_URL = "api/datas";
export const CRYPTO_URL = "https://api.coingecko.com/api/v3/";

// CREATE =>  POST: add a new data to the server
export function createCustomer(data) {
  return axios.post(CUSTOMERS_URL, { data });
}

// READ
export function findData(type, params) {
  if (type === 'user') {
    return axios.get(URL+'auth/user/login');
  } else if (type === 'wallet') {
    return axios.get(URL+'auth/user/wallet');
  } else if (type === 'currency_wallet') {
    return axios.get(`${URL}auth/user/wallet${params}`);
  } else if (type === 'config') {
    return axios.get(URL+'api/transaction/charges');
  } else if (type === 'transactions') {
    return axios.get(`${URL}api/transaction/all${params}`);
  } else if (type === 'trans') {
    return axios.get(`${URL}api/transaction/all${params}`);
  } else if (type === 'cards') {
    return axios.get(`${URL}api/card/all${params}`);
  } else if (type === 'payee') {
    return axios.get(`${URL}api/payee/all${params}`);
  } else if (type === 'crypto') {
    return axios.get(`${CRYPTO_URL}coins/markets${params}`);
  } else if (type === 'logs') {
    return axios.get(`${URL}api/log/all${params}`);
  } else if (type === 'bills') {
    return axios.get(`${URL}api/bills${params}`);
  } else if (type === 'single_trans') {
    return axios.get(`${URL}api/transaction${params}`);
  } else if (type === 'user_wallet') {
    return axios.get(URL+`auth/user/user/wallet${params}`);
  } else if (type === 'banks') {
    return axios.get(`${URL}api/transaction/banks`);
  } 
}

export function getCustomerById(dataId) {
  return axios.get(`${CUSTOMERS_URL}/${dataId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findCustomers(queryParams) {
  return axios.post(`${CUSTOMERS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the data on the server
export function updateCustomer(data) {
  return axios.put(`${URL}auth/user`, data);
}

export function addData(type, data) {
  if (type === 'card') {
    return axios.post(`${URL}api/card`, data);
  } else if (type === 'verify') {
    return axios.post(`${URL}api/card/verify`, data);
  }else if (type === 'fund') {
    return axios.post(`${URL}api/transaction/fund`, data);
  } else if (type === 'payee') {
    return axios.post(`${URL}api/payee`, data);
  } else if (type === 'banks') {
    return axios.post(`${URL}api/payee`, data);
  } else if (type === 'charge') {
    return axios.post(`${URL}api/transaction/charge`, data);
  } else if (type === 'validate_bills') {
    return axios.post(`${URL}api/bills/validate`, data);
  } else if (type === 'pay_bills') {
    return axios.post(`${URL}api/bills/pay`, data);
  } else if (type === 'transfer') {
    return axios.post(`${URL}api/transaction/send`, data);
  } else if (type === 'verify') {
    return axios.post(`${URL}api/card/verify`, data);
  }else if (type === 'fund') {
    return axios.post(`${URL}api/transaction/fund`, data);
  }else if (type === 'bank_verify') {
    return axios.post(`${URL}api/transaction/bank/verify`, data);
  } else if (type === 'search_user') {
    return axios.post(`${URL}accounts/search`, data);
  } else if (type === 'payout') {
    return axios.post(`${URL}api/payout/transfer`, data);
  } else if (type === 'quote') {
    return axios.post(`${URL}api/payout/get-quote`, data);
  } else if (type === 'get_token') {
    return axios.post(`${URL}api/bank/get-token`, data);
  }
}

// UPDATE Status
export function updateStatusForCustomers(ids, status) {
  return axios.post(`${CUSTOMERS_URL}/updateStatusForCustomers`, {
    ids,
    status
  });
}

// DELETE => delete the data from the server
export function deleteCustomer(dataId) {
  return axios.delete(`${CUSTOMERS_URL}/${dataId}`);
}

// DELETE Customers by ids
export function deleteCustomers(ids) {
  return axios.post(`${CUSTOMERS_URL}/deleteCustomers`, { ids });
}
