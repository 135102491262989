import React from "react";
import "./AuthBtn.css";

interface Props {
  handleClick: () => void;
  label: string;
  disabled?: true | false;
  loading?: true | false;
  
  // onChange?: (e?: any) => void;
  
}

const AuthBtn: React.FC<Props> = ({ label, handleClick, disabled, loading }) => {
  return (
    <div className='authBtncnt' style = {{
      opacity: loading || disabled ? .5 : 1
    }}>
      <button disabled = {disabled} onClick={handleClick}>{loading ? 'Loading....' : label}</button>
    </div>
  );
};

export default AuthBtn;
