import { persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken, resend_token } from "./authCrud";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  Login: "[Login] Action",
  RESET_TOKEN: "[RESET] TOKEN",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  OrgLoaded: "GET ORG"
};

const initialAuthState = {
  user: undefined,
  authToken: undefined
};

export const reducer = persistReducer(
  { storage, key: "chicken-licking-efvevfvfvervverver-auth", whitelist: ["user", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.RESET_TOKEN: {
        const { authToken } = action.payload;

        return { ...state, authToken };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.OrgLoaded: {
        const { organization } = action.payload;
        return { ...state, organization };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: authToken => ({ type: actionTypes.Login, payload: { authToken } }),
  register: authToken => ({
    type: actionTypes.Register,
    payload: { authToken }
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({ type: actionTypes.UserRequested, payload: { user } }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } }),
  fulfillToken: authToken => ({ type: actionTypes.RESET_TOKEN, payload: { authToken:  authToken} }),
  // fulfillOrg: user => ({ type: actionTypes.OrgLoaded, payload: { organization: user } })
};

export function* saga() {
  
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    
    const { data: user } = yield getUserByToken();
    
    if (!user.user.email_verified) {
     
      var body = {};
      body['email'] = user.user.email;
      body['type'] = "customer";
      
      yield resend_token(body);
      window.location = `/verify?email=${user.user.email}`;
    
      // navigate('/verify', {
      //     state: {
      //       email: user.user.email
      //     }
      // });
      return;
    } else if (!user.user.pin_added) {
      // navigate('/pin', {
      //     state: {
      //       email: user.user
      //     }
      // });
      window.location = `/pin?user=${user.user.email}`;
    
    }
    // console.log("user baby!!", user.user); return;
    yield put(actions.fulfillUser(user));
    // yield put(actions.fulfillOrg(user.organization));
  });
}
