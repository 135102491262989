import Otp from "../../../../../components/Otp/Otp";
import "./Verify.css";
import otpimg from "../../../../../assets/otppin.png";
import React, {useState} from "react";
import Appheader from "../../../../../components/Appheader/Appheader";

import { toast } from 'react-toastify';
import { verify_email } from '../../_redux/authCrud'
import * as auth from '../../_redux/authRedux'
import {  useDispatch } from "react-redux";

import { useSearchParams } from "react-router-dom";

const Verify = () => {
    const dispatch = useDispatch()
    const [otp, setOtp] = useState("");
    const [otpLe, setOtpL] = useState(0);
    function onChange (value: string) {
      setOtpL(value.length)
      setOtp(value)
    }
    const [loading, setLoading] = useState(false);
    const [searchParams] = useSearchParams();


    function submit() {

      if (!searchParams.get('email')) {
        toast.error('Set email address!'); return;
      }
      
      var body: any = {};
      body['token'] = otp;
      body['email'] = searchParams.get('email');
      body['type'] = 'customer';
      setLoading(true);
      verify_email(body)
          .then((response) => {
              setLoading(false);
              var data = response.data;
              if (data.error) {
                toast.error(data.error)
              } else {
                dispatch(auth.actions.login(data.user_token));
              }
        })
          .catch((error) => {
              console.log(error);
              setLoading(false);
              toast.error(`Error: ${error?.response?.data?.message || 'Network error occured signing up'}`)
          });
  
  }

    return (
      <div className='settransferpincnt'>
          <Appheader label="Verify your account by entering code sent to your email below" />
          <img src={otpimg} alt='' />
          <Otp 
          type = "password"
          value={otp} valueLength={4} onChange={onChange}  />
          <div className="settransferpincntdivider"></div>
          <button 
          style={{
            opacity: otpLe < 4 ? .5 : 1
          }}
          disabled={otpLe < 4}
          className="settransferconfirmbtn" onClick={() => submit()}>Next</button>
      </div>
    );
};

export default Verify;
