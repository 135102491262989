import React from "react";
import "./InputField.css";

interface Props {
  type: string;
  label: string;
  placeholder?: string;
  value?: string;
  required?: boolean;
  disabled?: boolean;
  initialValue?: string;
  status?: string;
  WithTopRight?: any;
  prefixSelector?: any;
  onChange?: (e?: any) => void;
  options?: any;
  showCount?: boolean;
  maxLength?: number;
  className?: string;
  onPressEnter?: any;
  defaultValue?: any;
}

const SelectField: React.FC<Props> = ({ type, label, onChange, options, placeholder, value, disabled, required }) => {
  return (
    <div className='inputfieldcnt'>
      <label>{label}</label>
      {/* <input 
      placeholder={placeholder || ''}
      onChange={onChange}
      value={value} 
      disabled={disabled}
      required={required}
      type={type} /> */}
      <select onChange={onChange} value={value}>
        {placeholder &&
        <option>{`${placeholder}`}</option>
        }
         
        {
            options && options.map((ele: any, i: number) => {
                return (
                    <option key = {i} value = {type === 'cards' ? ele._id : type === 'bank' ? ele.code : ele.value || ele.name}>{type === 'cards' ? `**** **** **** ${ele.last_four} (${ele.currency.toUpperCase()})` : type === 'bills' ? ele.label : ele.name}</option>
                )
            })
        }
      </select>

    </div>
  );
};

export default SelectField;
