import React from "react";
import "./Cryptopage.css";

import { useLocation, useNavigate } from "react-router-dom";
import Applayout from "../../../layouts/Applayout/Applayout";
import Cryptoheader from "../../../components/Cryptoheader/Cryptoheader";

const Cryptopage = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const handleBack = () => {
    navigate("/home");
  };

  const handleRefresh = () => {};
  return (
    <>
      <Applayout linkaddress={location.pathname} />
      <Cryptoheader
        handleBack={handleBack}
        handleRefresh={handleRefresh}
        label='Crypto'
      />
      <div className='cryptopagetop'>
        <div className='cryptomainwalletcard'>
          <h1>$ 7595.79</h1>
          <p>Main Wallet</p>
        </div>
      </div>
      <h3 className="cryptocointitle">
        Coins
      </h3>
    </>
  );
};

export default Cryptopage;
