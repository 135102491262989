import React from "react";
import "./Appheaderone.css";
import { closeicon } from "../../konstant";

interface Props {
  handleClick: () => void;
  label: string;
}

const Appheaderone: React.FC<Props> = ({ label, handleClick }) => {
  return (
    <div className='appheaderonecnt'>
      <div className='appheaderoneempty'></div>
      <h1 className='appheaderlabel'>{label}</h1>
      <button onClick={handleClick}>{closeicon}</button>
    </div>
  );
};

export default Appheaderone;
