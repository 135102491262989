import React, {useEffect, useState} from "react";
import "./Transaction.css";
import { useNavigate } from "react-router-dom";
import Appheadertwo from "../../../../components/Appheadertwo/Appheadertwo";
import { copyicon, downloadicon } from "../../../../konstant";
import { useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../_redux/home/Actions";
import * as functions from "../../../globals/functions";
import moment from "moment";

function Transaction() {
  let navigate = useNavigate();
  const { id } = useParams();
  const [data, set_data] = useState<any>(null);
  const handleClick = () => {
    navigate(-1);
  };

  const dispatch = useDispatch<any>();
  const { user, error, wallet, trans, listLoading, loading } = useSelector(
    (state: any) => ({
      user: state.auth.user.user,
      error: state.home.error,
      wallet: state.home.wallet,
      trans: state.home.trans,
      listLoading: state.home.listLoading,
      loading: state.home.listLoading || state.home.actionsLoading
        
    }),
    shallowEqual
  );

  const to_me = user._id === data?.recipient?._id;
  const [rec, set_rec] = useState(null);
  const [menu, set_menu] = useState<any>([]);


  useEffect(() => {
    var ignore;

    async function fetchData() {
      ignore = false;
      if (!ignore) {
        dispatch(actions.fetchData("single_trans", `?trans_id=${id}`)).then((data: any) => {
          // console.log(data)
          if (data && data.success) {
            set_data(data.success[0])
          }
        });
      }
    }

    if (id) {
      fetchData();
    }
    
    return () => {
      ignore = true;
      console.log("unsubscribe ", ignore);
    };
  }, [id]);


  // alert(id)
  function getTitle() {
    if (data.type === 'bill') {
        return 'To';
    }
    if (data.type === 'bank_transfer') {
        return 'To';
    }
    if (to_me) {
        return 'From';
    }
    return 'To';
}


function getTitlYoue() {
    if (data.type === 'bill') {
        return 'You sent';
    }
    if (data.type === 'bank_transfer') {
        return 'You sent';
    }
    if (to_me) {
        return 'You got';
    }
    return 'You sent';
}


function getCur() {
    if (data.type === 'bill') {
        return data.currency;
    }
    if (data.type === 'bank_transfer') {
        return data.currency;
    }
    if (to_me) {
        return data.currency_rec;
    }
    return data.currency;
}

function getAm() {
    if (data.type === 'bill') {
        return data.amount;
    }
    if (data.type === 'bank_transfer') {
        return data.amount;
    }
    if (to_me) {
        return data.amount_receive;
    }
    return data.amount;
}


function getChan(key: any) {
    if (data.type === 'bill') {
        return `${data.remark}`;
    }
    if (data.type === 'bank_transfer') {
        return `${data?.bank?.account_name || 'Bank Name'}`
    }

    return key;
}


 useEffect(() => {
     if (data) {
        var key_person = to_me ? data?.sender : data?.recipient || null;
        set_rec(key_person);

        if (key_person) {
            var menn = [{
                title: `${getTitle()}`,
                // value: `${key_person?.first_name} ${key_person?.last_name}`,
                value: `${getChan(`${key_person?.first_name} ${key_person?.last_name}`)}`,
            }, {
                title: 'Payment Method',
                value: `${data?.channel.toUpperCase()}`,
            }, 
            {
                title: 'Status',
                value: `${data?.status.toUpperCase()}`,
            },
            {
                title: 'Transaction Amount',
                value: `${functions.symbol(getCur())} ${data?.amount_receive.toFixed(2)}`,
            },
            {
                title: 'Exchange Rate',
                value: `${Number(data.rate).toFixed(2)}`,
            },
            {
                title: 'Transaction Fee',
                value: `${functions.symbol(getCur())} ${data?.charge.toFixed(2)}`,
            }
        
            ]

            if (data.type === 'bank_transfer') {
                menn.push(
                    {
                        title: 'Bank',
                        value: `${data?.bank?.bank_name || data?.bank?.account_number}`,
                    }
                )                    
            }
            set_menu(menn)
        }

     }
 }, [data])




function getD(date: any) {
    if (moment().format('L') !== moment(date).format('L')) {
        return `${moment(date).format('ll')} • ${moment(date).format('LT')}`;
    } else {
        return `${moment(date).format('LT')}`;
    }
}




  return (
    data &&
    <div>
      <Appheadertwo handleClick={handleClick} label='Transaction' />
      <div className='sucrec'>
        <p className='tsuc'>{data.status.toUpperCase()}</p>

        <div className='trec'>
          <label>Receipt</label>
          {downloadicon}
        </div>
      </div>

      {menu.length > 0 &&
      <div className='transdetails'>
        {
          menu.map((ele: any, i: number) => {
            return (
              <div key = {i} className='transdetailsitem'>
                <p className='transtitle'>{ele.title}</p>
                <p className='transtext'>{ele.value}</p>
              </div>
            )
          })
        }
      
      </div>}
      <p className='transtime'>Time: {getD(data.createdAt)}</p>
      <div className='transref'>
        <div className='transrefleft'>
          <p className='transrefnum'>Reference Number:</p>
          <p className='transferno'>{data.transaction_id}</p>
        </div>

        <div className='transrefright'>{copyicon}</div>
      </div>
      <div className="trsentmny">
        <label>{`${getTitlYoue()}`}</label>
        <p className="trsenttext">
          {`${functions.symbol(getCur())} ${getAm().toFixed(2)}`}
        </p>
      </div>
      <div className="trsentmny">
        <label>Transaction Remark</label>
        <p className="trsenttext">
          {data.remark}
        </p>
      </div>
    </div>
  );
}

export default Transaction;
