import React from "react";
import "./InputField.css";

interface Props {
  type: string;
  label: string;
  placeholder?: string;
  value?: string;
  required?: boolean;
  disabled?: boolean;
  initialValue?: string;
  status?: string;
  WithTopRight?: any;
  prefixSelector?: any;
  onChange?: (e?: any) => void;
  onBlur?: (e?: any) => void;
  showCount?: boolean;
  maxLength?: number;
  className?: string;
  onPressEnter?: any;
  defaultValue?: any;
}

const InputField: React.FC<Props> = ({ type, label, onChange, onBlur, placeholder, value, disabled, required }) => {
  return (
    <div className='inputfieldcnt'>
      <label>{label}</label>
      <input 
      placeholder={placeholder || ''}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      disabled={disabled}
      required={required}
      type={type} />
    </div>
  );
};

export default InputField;
