import React from "react";
import "./Actionpage.css";
import Appheaderone from "../../../components/Appheaderone/Appheaderone";
import { useNavigate } from "react-router-dom";
import { addmoneyicon, withdrawicon } from "../../../konstant";

function Actionpage() {
  let navigate = useNavigate();
  const handleClick = () => {
    navigate(-1);
  };
  return (
    <>
      <Appheaderone label='Action' handleClick={handleClick} />

      <div className='actionpagebody'>
        <div
          className='actionpagebodyitems'
          onClick={() => navigate("/home/withdraw")}
        >
          <div className='actionpagebodyitemsleft'>{withdrawicon}</div>
          <div className='actionpagebodyitemsright'>
            <h2>Withdraw Money</h2>
            <p>
              Withdraw funds from your wallet to any bank account in the same
              currency at no-fee.
            </p>
          </div>
        </div>
        <div className='actionpagebodyitems'
           onClick={() => navigate("/home/fund")}
        >
          <div className='actionpagebodyitemsleft'>{addmoneyicon}</div>
          <div className='actionpagebodyitemsright'>
            <h2>Add Money</h2>
            <p>Add money to your wallets using different payment methods.</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Actionpage;
