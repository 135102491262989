import React from "react";
import "./Alreadysign.css";

interface Props {
  name: string;
  label: string;
  handleAuthClick: () => void;
}

const Alreadysign: React.FC<Props> = ({ name, label, handleAuthClick }) => {
  return (
    <div className='alreadysigncnt'>
      <p>
        {name} <span onClick={handleAuthClick}>{label}</span>
      </p>
    </div>
  );
};

export default Alreadysign;
