import Otp from "../../../../../components/Otp/Otp";
import "./Verify.css";
import otpimg from "../../../../../assets/otppin.png";
import React, {useState} from "react";
import Appheader from "../../../../../components/Appheader/Appheader";

import { toast } from 'react-toastify';
import { edit_user } from '../../_redux/authCrud'
import * as auth from '../../_redux/authRedux'
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import CryptoJS from "crypto-js";

import { useSearchParams } from "react-router-dom";

const Pin = () => {
    const dispatch = useDispatch()
    const [otp, setOtp] = useState("");
    const [otpLe, setOtpL] = useState(0);
    function onChange (value: string) {
      setOtpL(value.length)
      setOtp(value)
    }
    const [loading, setLoading] = useState(false);
    const [searchParams] = useSearchParams();

    const { user, error } = useSelector(
      (state: any) => ({ user: state.auth.user ,
        error: state.transfer.error}),
      shallowEqual
    );

    function submit() {

      if (!searchParams.get('email')) {
        toast.error('Set email address!'); return;
      }

      var uds = user._id;
      var sec_code = CryptoJS.AES.encrypt(otp, process.env.REACT_APP_KEY+'_'+uds).toString();
     
      
      var body: any = {};
      body['security_pin'] = sec_code;
      setLoading(true);
      edit_user(body)
          .then((response) => {
              setLoading(false);
              var data = response.data;
              if (data.error) {
                toast.error(data.error)
              } else {
                dispatch(auth.actions.login(data.user_token));
              }
        })
          .catch((error) => {
              console.log(error);
              setLoading(false);
              toast.error(`Error: ${error?.response?.data?.message || 'Network error occured signing up'}`)
          });
  
  }

    return (
      <div className='settransferpincnt'>
          <Appheader label="Set Transaction PIN" />
          <img src={otpimg} alt='' />
          <Otp 
          type = "password"
          value={otp} valueLength={4} onChange={onChange}  />
          <div className="settransferpincntdivider"></div>
          <button 
          style={{
            opacity: otpLe < 4 ? .5 : 1
          }}
          disabled={otpLe < 4}
          className="settransferconfirmbtn" onClick={() => submit()}>Next</button>
      </div>
    );
};

export default Pin;
