import React, {useEffect, useState} from "react";
// import "./Transaction.css";
import { useNavigate } from "react-router-dom";
import InputField from "../../../../components/InputField/InputField";
import SelectField from "../../../../components/InputField/SelectField";
import AuthBtn from "../../../../components/AuthBtn/AuthBtn";


function Tespay(props: any) {
  let navigate = useNavigate();


  return (
    <div>
        <InputField type='text'
        value = {props.wallet_id}
        onBlur = {() => props.onblur()}
        // loading = {props.loading}
        onChange = {(e) => props.set_wallet_id(e.target.value)}
        label='Tespay Email Address' />

        <InputField 
        type='text' 
        disabled = {true}
        value = {props.rec_info ? `${props.rec_info.first_name} ${props.rec_info.last_name}` : ''}
        label='Fullname' />

        <InputField 
        type='text' 
        onChange = {(e:any) => props.set_info(e.target.value)}
        value = {props.info}
        label='Transaction Remarks' />
        


        <AuthBtn 
            label='Continue' 
            loading = {false}
            disabled = {
                !props.rec_info    
                ||
                props.wallet_id === ''
                ||
                props.info === ''
            }   
            handleClick={() => props.proceed()}
        />
 
    </div>
  );
}

export default Tespay;
