import {all} from 'redux-saga/effects';
import {combineReducers} from 'redux';

import * as auth from '../../app/modules/auth/_redux/authRedux';
import { homeSlice } from '../../app/modules/home/_redux/home/Slice';

export const rootReducer = combineReducers({
  auth: auth.reducer,
  home: homeSlice.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([auth.saga()])
}
