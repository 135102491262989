import React, {useEffect, useState} from "react";
// import "./Transaction.css";
import { useNavigate } from "react-router-dom";
import Appheadertwo from "../../../../components/Appheadertwo/Appheadertwo";
import InputField from "../../../../components/InputField/InputField";
import SelectField from "../../../../components/InputField/SelectField";
import AuthBtn from "../../../../components/AuthBtn/AuthBtn";

import { copyicon, downloadicon } from "../../../../konstant";
import { useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../_redux/home/Actions";
import * as functions from "../../../globals/functions";
import moment from "moment";
import { toast } from 'react-toastify';
import {useLocation} from 'react-router-dom';

import Tespay from "./tespay";
import Bank from "./bank";

function Details(props: any) {
  let navigate = useNavigate();
  const location = useLocation();

//   console.log(location)
   
  const { id } = useParams();
  const handleClick = () => {
    navigate(-1);
  };


  useEffect(() => {
    if(!location) {
        navigate('/')
    }
  }, [location])

  const dispatch = useDispatch<any>();
 
  
  const transfer_types = [
    {
        "name": "Tespay Transfer",
        "value": "tespay",
        "_id": "tespay"
    },
    {
        "name": "Bank Transfer",
        "value": "bank",
        "_id": "bank"
    }
]
const data: any = location.state;// = props.route.params;


const { user, banks, error, wallet, listLoading, actionsLoading } = useSelector(
    (state: any) => ({ user: state.auth.user.user,
        error: state.home.error,
        wallet: state.home.wallet,
        banks: state.home.banks,
        listLoading: state.home.listLoading,
        actionsLoading: state.home.actionsLoading
    }),
    shallowEqual
);


const [email, setEmail] = useState('');
const [transfer_type, select_tranfer_type] = useState<string>(transfer_types[0].value);

const [type, set_type] = useState(transfer_types[0].value);
const [wallet_id, set_wallet_id] = useState('');
const [error_k, set_error] = useState(null);
const [loading, set_loading] = useState(false);
const [wallet_info, set_wallet_info] = useState(null);
const [info, set_info] = useState('');
const [swift, set_swift] = useState('');
const [loading_3, setloading] = useState(false);
const [rec_info, set_recieve_info] = useState(null);
const [save, set_save] = useState(false);


const [account_number, set_account_number] = useState(''); //000123456789
const [routing_number, set_routing_number] = useState(''); //110000000
const [account_name, set_account_name] = useState('');
const [bank_code, set_bank_code] = useState('');
const [account_type, set_account_type] = useState('individual');
const [bank, set_bank] = useState<any>(null);



const [bank_recipient_info, set_Bank_recipient_info] = useState<any>(null);
// const [wallet_valid, is] = useState('');


function setS(d: any) {
    select_tranfer_type(d);
    set_Bank_recipient_info(null);
    set_wallet_info(null);
    
    if (d === 'bank') {
        //check if nigeria
        if (!banks) {
            dispatch(actions.fetchData('banks'));
        }
    }
}

function proceed () {
    // if (!user.pin_added) {
    //     navigate('/profile/pin');
    //     toast.error('Firstly, set your transaction PIN');
    // } else {
        navigate('/home/payment', {
            state: {
                data,
                rec_info: rec_info,
                wallet_info: wallet_info,
                wallet_id: wallet_id,
                account_name,
                swift,
                account_number,
                info: info
            }
        });
    // }
}


async function proceedToBank () {
    var rec_co = data?.rec_country?.toLowerCase();
    if (!user.pin_added) {
        // props.navigation.navigate('Pin');
        toast.error('Firstly, set your transaction PIN');
    } else {
        var bank_info: any = {};
            
        if (rec_co === 'ng') {
            if (!bank) {
                return;
            }
            bank_info['country'] = rec_co;
            bank_info['account_number'] = account_number;
            bank_info['bank_name'] = bank.name;
            bank_info['bank_code'] = bank.code;
            bank_info['name'] = bank_recipient_info.details.account_name;
            bank_info['recipient_code'] = bank_recipient_info.recipient_code;
            bank_info['type'] = bank_recipient_info.type;
            bank_info['save'] = save;
            // console.log(bank_info); return;
               
            props.navigation.navigate('Payment', {
                data,
                bank_recipient_info: bank_info,
                info: info
            });

        } else {
            const params = {
                accountNumber: account_number,
                routingNumber: routing_number,
                accountHolderName: account_name,
                accountHolderType: account_type,
                currency: functions.returnCurrency(rec_co),
                country: rec_co
            }
            // console.log(params); return;
            setloading(true)
        

            dispatch(actions.addData('get_token', params)).then((res: any) => {
                setloading(false)
                // console.log(res); return;
                const token = res?.success;
                if (res && res.success) {

                    bank_info['country'] = rec_co.toLowerCase();
                    bank_info['account_number'] = account_number;
                    bank_info['bank_name'] = token.bank_account.bank_name;
                    bank_info['bank_code'] = token.bank_account.id; // token.id
                    bank_info['name'] = token.bank_account.account_holder_name;
                    bank_info['recipient_code'] = token.id;
                    bank_info['type'] = token.bank_account.account_holder_type;
                    bank_info['save'] = save;
                    console.log(bank_info);
                
                    // props.navigation.navigate('Payment', {
                    //     data,
                    //     bank_recipient_info: res.success,
                    //     info: info
                    // });
                    // dispatch(dash_actions.fetchData('wallet'))
                    // dispatch(dash_actions.fetchData('transactions', '?limit=3'))
                
                    // props.navigation.navigate('Confirm', {
                    //     title: 'Transfer Submitted',
                    //     subtitle: 'Your transfer is being processed and should be dispatched to your the recipient shortly. You will be notified when this is done.'
                    // })   
                } 
            })


            // try {
            //     var token = await stripe.createTokenWithBankAccount(params);
            //     setloading(false)

            //     bank_info['country'] = rec_co.toLowerCase();
            //     bank_info['account_number'] = account_number;
            //     bank_info['bank_name'] = token.bankAccount.bankName;
            //     bank_info['bank_code'] = token.bankAccount.bankAccountId;
            //     bank_info['name'] = token.bankAccount.accountHolderName;
            //     bank_info['recipient_code'] = token.tokenId;
            //     bank_info['type'] = token.bankAccount.accountHolderType;
            //     bank_info['save'] = save;
                
            // //    console.log(bank_info); return;
            //     props.navigation.navigate('Payment', {
            //         data,
            //         bank_recipient_info: bank_info,
            //         info: info
            //     });
    
            // } catch (e) {
            //     setloading(false)
            //     console.log("EE",e)
            //     toast.error(e.message);
            // }
        }
        
        
    }
}


function getWalletInfo() {
    set_error(null);
    set_recieve_info(null);
    set_wallet_info(null);
                           
    
    if (wallet_id === '') {
        toast.error('Enter valid wallet ID');
        return;
    }

    dispatch(actions.fetchData('user_wallet', `?email=${wallet_id}&currency=${functions.returnCurrency(data.rec_country)}`)).then((res: any) => {
        // console.log(res);
       
        // if (res.success) {
        //     if (res.success.currency !== functions.returnCurrency(data.rec_country)) {
        //         toast.error(`Error! Wallet currency is ${res.success.currency.toUpperCase()}, and not ${functions.returnCurrency(data.rec_country).toUpperCase()}`);
        //         return;   
        //     }
        // }
        

        set_wallet_info(res?.wallet);
        set_recieve_info(res?.success);
    });

}


function getBankName(account_number: any, bank: any) {
    set_error(null);
    set_Bank_recipient_info(null);
   
    if (!bank) {
        toast.error('Choose a bank please');
        return;
    }
    
    if (account_number.length < 10) {
        toast.error('Enter a valid account number');
        return;
    }

    var body:any = {};
    body['account_number'] = account_number;
    body['bank_code'] = bank;

    set_bank(bank)
    
    dispatch(actions.addData('bank_verify', body)).then((res: any) => {
        // console.log(res);
        // return;
        if (!res) {
            return;
        }
        if (res.error) {
            // set_error(res.error);
        } else {
            if (res.success) {
                // console.log(res.success);
                set_Bank_recipient_info(res.success);
            } else {
                toast.error("Error occurred getting wallet info");
            }
        }
    })

}





  return (
    <div>
      <Appheadertwo handleClick={handleClick} label="Enter receiver's details" />
      
        <SelectField 
        options = {transfer_types}
        value = {transfer_type}
        onChange={(e) => {
            setS(e.target.value)
        }}
        type='text' label='Select Payment Type' />
        
        {
            transfer_type === 'tespay' ?
            <Tespay
                wallet_id = {wallet_id}
                set_wallet_id = {set_wallet_id}
                wallet_info = {wallet_info}
                rec_info = {rec_info}
                set_info = {set_info}
                rec_country = {data?.rec_country}
                info = {info}
                proceed = {proceed}
                error = {error_k}
                onblur = {getWalletInfo}
                loading = {loading || listLoading}
            />

            :

            <Bank 
                    rec_info = {data.rec_country}
                    bank_recipient_info = {bank_recipient_info}
                    getBankName = {getBankName}
                    swift = {swift}
                    set_swift = {set_swift}
                    info = {info}
                    set_info = {set_info}
                    proceed = {proceedToBank}
                    bank_code = {bank_code}
                    set_bank_code = {set_bank_code}
                    actionsLoading = {actionsLoading}
                    listLoading = {listLoading}
                    loading_3 = {loading_3}
                    set_account_number = {set_account_number}
                    account_name = {account_name}
                    account_number = {account_number}
                    account_type = {account_type}
                    routing_number = {routing_number}
                    set_account_type = {set_account_type}
                    set_account_name = {set_account_name}
                    set_routing_number = {set_routing_number}
                    set_save = {set_save}
                    save = {save}
                    error = {error_k}
                
                />
        }
 
    </div>
  );
}

export default Details;
