// import React from "react";
// import ReactDOM from "react-dom/client";
// import "./index.css";
// import router from "./App";
// import reportWebVitals from "./reportWebVitals";
// import { RouterProvider } from "react-router-dom";

// const root = ReactDOM.createRoot(
//   document.getElementById("root") as HTMLElement
// );
// root.render(
//   <React.StrictMode>
//     <RouterProvider router={router} />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);


import ReactDOM from 'react-dom/client';
import {PersistGate} from 'redux-persist/integration/react'
import {Provider} from 'react-redux'
import * as _redux from './setup'
import store, {persistor} from './setup/redux/Store'
import axios from 'axios'
import {App} from './app/App'

import './index.css';

const {PUBLIC_URL} = process.env
_redux.setupAxios(axios, store)



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
       <Provider store={store}>
       
        <PersistGate persistor={persistor} loading={null}>
          <App basename={PUBLIC_URL} />
        </PersistGate>
    
      </Provider>
);

 