import React, {useEffect, useState} from "react";
// import "./Transaction.css";
import { useNavigate } from "react-router-dom";
import InputField from "../../../../components/InputField/InputField";
import SelectField from "../../../../components/InputField/SelectField";
import AuthBtn from "../../../../components/AuthBtn/AuthBtn";
import Appheadertwo from "../../../../components/Appheadertwo/Appheadertwo";


function Confirm(props: any) {
  let navigate = useNavigate();
  const handleClick = () => {
    navigate('/');
  };


  return (
    <div>
        <Appheadertwo handleClick={handleClick} label="Payment Confirmation" />
      
       
 
    </div>
  );
}

export default Confirm;
