import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {Navigate,Route, Routes} from 'react-router-dom'
import * as auth from '../auth/_redux/authRedux';

export function Logout() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(auth.actions.logout())
    // document.location.reload()
  }, [dispatch])

  return (
    <Routes>
      {/* <Navigate to='/auth/login' /> */}
      <Route path="/" element={<Navigate to="/login" />}/>
    </Routes>
  )
}
