import React from "react";
import { activedeleteicon } from "../../konstant";
import "./ActiveSubdetails.css";

interface Props {
  handleClick: () => void;
  handleView: () => void;
  subname: string;
  status: string;
  date: string;
  amount: string;
}

const ActiveSubdetails: React.FC<Props> = ({
  handleClick,
  handleView,
  status,
  subname,
  date,
  amount,
}) => {
  return (
    <div className='activesubcnt' onClick={handleView}>
      <div className='activesubcntleft'>
        <button className='activesubdel' onClick={handleClick}>{activedeleteicon}</button>

        <div className='actsubdate'>
          <p className='actsubsname'>{subname}</p>
          <p className='actdate'>{date}</p>
        </div>
      </div>

      <div>
        <p className='actsubsname'>{amount}</p>
        <p className='actdate' style={{textTransform: 'capitalize'}}>{status}</p>
      </div>
    </div>
  );
};

export default ActiveSubdetails;
