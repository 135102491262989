import React from "react";
import "./Cryptoheader.css";
import { backicon, refreshicon } from "../../konstant";

interface Props {
  handleRefresh: () => void;
  label: string;
  handleBack: () => void;
}

const Cryptoheader: React.FC<Props> = ({
  handleRefresh,
  label,
  handleBack,
}) => {
  return (
    <div className='appheaderonecnt'>
      <button onClick={handleBack}>{backicon}</button>
      <h1 className='appheaderlabel'>{label}</h1>
      <button onClick={handleRefresh}>{refreshicon}</button>
    </div>
  );
};

export default Cryptoheader;
