import React, {useEffect, useState} from "react";
// import "./Transaction.css";
import { useNavigate } from "react-router-dom";
import Appheadertwo from "../../../../components/Appheadertwo/Appheadertwo";
import InputField from "../../../../components/InputField/InputField";
import SelectField from "../../../../components/InputField/SelectField";
import AuthBtn from "../../../../components/AuthBtn/AuthBtn";

import { copyicon, downloadicon } from "../../../../konstant";
import { useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../_redux/home/Actions";
import * as functions from "../../../globals/functions";
import moment from "moment";
import { toast } from 'react-toastify';
import {useLocation} from 'react-router-dom';

import Tespay from "./tespay";
import Bank from "./bank";

function Payment(props: any) {
  let navigate = useNavigate();
  const location = useLocation();
  const [trans, setTrans] = useState([]);
  const [selected_card, setCard] = useState(null);
  const [loading2, setLoading] = useState(false);
  const [rate_data, setRate] = useState<any>(null);
  const [wallet, setW] = useState<any>(null);

  const { id } = useParams();
  const handleClick = () => {
    navigate(-1);
  };


  useEffect(() => {
    if(!location) {
        navigate('/')
    }
  }, [location])

  const dispatch = useDispatch<any>();
 
  
  const transfer_types = [
    {
        "name": "Tespay Transfer",
        "value": "tespay",
        "_id": "tespay"
    },
    {
        "name": "Bank Transfer",
        "value": "bank",
        "_id": "bank"
    }
]
const data: any = location.state;// = props.route.params;



console.log("cddfsDS", data);

const { user, cards,wallets, error, loading } = useSelector(
    (state: any) => ({ user: state.auth.user,
        cards: state.home.cards,
        error: state.home.error,
        wallets: state.home.wallet,
        loading: state.home.listLoading || state.home.actionsLoading
    }),
    shallowEqual
);




useEffect(() => {
    dispatch(actions.fetchData('currency_wallet', `?currency=${functions.returnCurrency(data.data.init_country)}`)).then((data: any) => {
        if (data && data.length > 0) {
            setW(data[0])
        } else {
            setW([])
        }
    });
}, [])



useEffect(() => {

    if (selected_card) {
        // getR();
    }

}, [selected_card])

function getR() {
    setRate(null);
    var body: any = {};
    body['amount'] = Number(parseFloat(data?.data?.amount_sending.replace(/,/g, '')));
    body['type'] = 'wallet';
    body['sending_currency'] = functions.returnCurrency(data?.data?.init_country);
    body['rec_currency'] = functions.returnCurrency(data?.data?.rec_country);
    
    dispatch(actions.addData('charge', body)).then((res: any) => {
        if (res.success) {
            setRate(res.success);
        }
    }).catch((error: any) => {
        console.log("eer", error)
    });
}

async function submit() {
    
                     


    var body: any = {};
    body['amount'] = Number(parseFloat(data?.data?.amount_sending.replace(/,/g, '')));
    body['amount_to_charge'] = data?.data?.rate_data?.convertedAmount;
    body['rec_currency'] = data?.data?.rate_data?.rec_currency;
    body['currency'] = data?.data?.rate_data?.your_currency;
    body['wallet_id'] = wallet._id;
    body['exchange'] = data?.data?.rate_data?.exchange;
    body['extra_charges'] = data?.data?.rate_data?.extra_charges;
    body['recipient'] = data?.rec_info?._id;
    body['remark'] = data.info;
    if (data.bank_recipient_info) {
        body['account_number'] = data.bank_recipient_info.account_number;
        body['recipient_code'] = data.bank_recipient_info?.recipient_code;
        body['bank_code'] = data.bank_recipient_info.bank_code;
        body['account_name'] = data.bank_recipient_info.name;
        body['bank_name'] = data.bank_recipient_info?.bank_name;
    }


    if (data.bank_recipient_info) {
   
        dispatch(actions.addData('payout', body)).then((res: any) => {
            // console.log(res); return;
            if (res && res.success) {
                dispatch(actions.fetchData('wallet'))
                dispatch(actions.fetchData('transactions', '?limit=3'))
            
                navigate('/home/success', {
                    state: {
                        title: 'Transfer Submitted',
                        subtitle: 'Your transfer is being processed and should be dispatched to your the recipient shortly. You will be notified when this is done.'
                    }
                })   
            } 
        });


       
        
    } else {
        // transfer

        dispatch(actions.addData('transfer', body)).then((res: any) => {
            // console.log(res); return;
            if (res && res.success) {
                dispatch(actions.fetchData('wallet'))
                dispatch(actions.fetchData('transactions', '?limit=3'))
                    
                navigate('/home/success', {
                    state: {
                        title: 'Transfer Submitted',
                        subtitle: 'Your transfer is being processed and should be dispatched to your the recipient shortly. You will be notified when this is done.'
                    }
                })   
            }
            
        });
    }
    
}
    


console.log(wallet)


  return (
    <div>
      <Appheadertwo handleClick={handleClick} label="Select Payment Method" />
        
        <SelectField 
        options = {wallet ? [{
            name: `${functions.symbol(wallet.currency)} ${functions.number_format(wallet.balance)} Balance`,
            value: wallet._id
        }] : 0}
        value = {wallet}
        onChange={(e) => setCard(e.target.value)}
        type='text' label='Select Payment Type' />
       

       {rate_data &&
        <p className="thesawapprice" style = {{
            marginBottom: 10
        }}>{`You will be charged ${rate_data.your_currency.toUpperCase()} ${functions.number_format(rate_data.convertedAmount)} for this transaction`}</p>
    }
 

       <AuthBtn 
            label='Make Payment' 
            loading = {false}
            disabled = {!wallet} 
                   
            handleClick={() => submit()}
        />

    </div>
  );
}

export default Payment;
