import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./Signup.css";
import Appheader from "../../../../../components/Appheader/Appheader";
import InputField from "../../../../../components/InputField/InputField";
import AuthBtn from "../../../../../components/AuthBtn/AuthBtn";
import Alreadysign from "../../../../../components/Alreadysign/Alreadysign";
import 'react-phone-number-input/style.css'
// import PhoneInput from 'react-phone-number-input'
import '../../../../../components/InputField/InputField.css';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'
import { toast } from 'react-toastify';
import { register } from '../../_redux/authCrud'
import * as auth from '../../_redux/authRedux'
import {  useDispatch } from "react-redux";

function Signup() {
  let navigate = useNavigate();
  const dispatch = useDispatch()
  const handleClick = () => {
    navigate("/signupsubmit");
  };

  const handleAuthClick = () => {
    navigate("/signin");
  };

  const [first_name, setfirst_name] = useState('');
  const [last_name, setlast_name] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [cpassword, csetPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [terms, setTerms] = useState(false);
  const [error, setError] = useState(null);
  const [value, setValue] = useState<any>('')
 
  const phoneRef = useRef<any>();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [data, setData] = useState<any>({});
  const [phone_err, setPhoneErr] = useState('');
    


    
  function submit() {


    if ( email === '' || password === '' || first_name === '' || last_name === '' ) {
      toast.error('Enter all input');
      return;
    } else if (phone_err) {
      toast.error(phone_err);
      return;
    } else if (password !== cpassword) {
      toast.error("Password doesn't match!");
      return;
    }

    var body: any = {};
    body['first_name'] = first_name;
    body['last_name'] = last_name;
    body['email'] = email;
    body['password'] = password;
    body['type'] = 'customer';
    body['phone'] = formatPhoneNumberIntl(`+${data?.dialCode}${phoneNumber}`).replace(/\s/g, ''); //values.tel;
    body['countryCode'] = `+${data?.dialCode}`;
            
    // body['acceptTerms'] = terms;
    // body['country_code'] = `${phone.getCountryDataByCode().iso2.toUpperCase()}`;
    // console.log(phone);
    // console.log(body); return; ade@ddd.com


    setLoading(true);
    register(body)
        .then((response) => {
            setLoading(false);
            var data = response.data;
            if (data.error) {
              toast.error(data.error)
            } else {
              dispatch(auth.actions.login(data.user_token));
            }
      })
        .catch((error) => {
            console.log(error);
            setLoading(false);
            toast.error(`Error: ${error?.response?.data?.message || 'Network error occured signing up'}`)
        });

}

function showError (error: any) {
    setError(error)
    setTimeout(()=>{
        setError(null)
    }, 3000); 
}


  return (
    <div className='signupcnt'>
      <Appheader label='Welcome' />
      <InputField label='First Name'
       onChange={(e: any) => setfirst_name(e.target.value.trim())}
       type='text' />
      <InputField label='Last Name' 
      onChange={(e: any) => setlast_name(e.target.value.trim())}
      type='text' />
      <InputField label='Email Address' 
      onChange={(e: any) => setEmail(e.target.value.trim())}
      type='email' />
      <div className='inputfieldcnt'>
        <label>Enter phone number</label>
        <IntlTelInput
            ref = {phoneRef}
            preferredCountries={['ng', 'za', "us", "gb", 'gh']}
            format = {true}
            onPhoneNumberFocus = {(isValid, number, obj) => {
                var num = `+${obj?.dialCode}${number}`;
                var isValidR = isValidPhoneNumber(num)
                setPhoneErr(isValidR ? "" : "Enter a valid phone number")
              }}
              onPhoneNumberBlur= {(isValid, number, obj) => {
                var num = `+${obj?.dialCode}${number}`;
                var isValidR = isValidPhoneNumber(num)
                setPhoneErr(isValidR ? "" : "Enter a valid phone number")
              }}
              onPhoneNumberChange = {(isValid, number, obj) => {
                  var num = `+${obj?.dialCode}${number}`;
                  var isValidR = isValidPhoneNumber(num)
                  setPhoneErr(isValidR ? "" : "Enter a valid phone number")
                  setPhoneNumber(number)
                  setData(obj)
              }}
              inputClassName="intl-tel-inddput"
            />
      </div>

      <InputField label='Password' 
      onChange={(e: any) => setPassword(e.target.value.trim())}
      type='password' />
      <InputField label='Confirm Passord'
      onChange={(e: any) => csetPassword(e.target.value.trim())}
      type='password' />
      <AuthBtn label='Register' 
      loading = {loading}
      handleClick={submit} />
      <Alreadysign
        name='Already have an account?'
        label='Sign In'
        handleAuthClick={handleAuthClick}
      />
      <p className='registeragree'>
        By creating your account you have to agree with our Terms and Conditions
      </p>
    </div>
  );
}

export default Signup;
