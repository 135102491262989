import React, {useEffect, useState} from "react";
// import "./Transaction.css";
import { useNavigate } from "react-router-dom";
import NG from "./ng";
import US from "./us";

function Bank(props: any) {
  let navigate = useNavigate();
    // var t = props.t;        
 
  return (
    <div>

       
{
            props.rec_info === 'ng' 
            ?
            <NG 
                getBankName = {props.getBankName}
                bank_recipient_info = {props.bank_recipient_info}
                info = {props.info}
                set_account_number = {props.set_account_number}
                account_number = {props.account_number}
                account_name = {props.account_name}
                set_info = {props.set_info}
                proceed = {props.proceed}
                set_save = {props.set_save}
                save = {props.save}
            />
            : 
            // props.rec_info === 'us' || props.rec_info === 'ca' ?
            <US 
                getBankName = {props.getBankName}
                set_account_number = {props.set_account_number}
                account_name = {props.account_name}
                account_number = {props.account_number}
                account_type = {props.account_type}
                swift = {props.swift}
                set_swift = {props.set_swift}
                bank_code = {props.bank_code}
                set_bank_code = {props.set_bank_code}
                routing_number = {props.routing_number}
                set_account_type = {props.set_account_type}
                set_account_name = {props.set_account_name}
                set_routing_number = {props.set_routing_number}
                error = {props.error_k}
                loading_3 = {props.loading_3}
                proceed = {props.proceed}
                set_save = {props.set_save}
                save = {props.save}
            />
                    
            // : 
            // null
        }
    </div>
  );
}

export default Bank;
