import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Bills.css";
import Applayout from "../../../layouts/Applayout/Applayout";
import { arrowdownlineicon } from "../../../konstant";
import ActiveSubdetails from "../../../components/ActiveSubdetails/ActiveSubdetails";
import SelectField from "../../../components/InputField/SelectField";
import InputField from "../../../components/InputField/InputField";
import AuthBtn from "../../../components/AuthBtn/AuthBtn";
import Appheadertwo from "../../../components/Appheadertwo/Appheadertwo";


import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../home/_redux/home/Actions";
import * as functions from "../../globals/functions";
import moment from "moment";

function SingleBill() {
  const dispatch = useDispatch<any>();
  const location = useLocation();
  let navigate = useNavigate();
  
  const handleClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    if(!location) {
        navigate('/')
    }
  }, [location])


  const data: any = location.state.data;// = props.route.params;

  const first_level = ['airtime', 'internet', 'power'];


  const [sending_amount, setSending] = useState('10');
  const [type_data, set_type_data] = useState<any>([]);
  const [type_single_data, set_type_single__data] = useState<any>([]);
  const [allData, setAll] = useState([]);
  const [biller_code, set_code] = useState(null);
  const [item_code, set_item] = useState(null);
  const [disabled, set_dis] = useState(false);




  const { user, loading, cards, error } = useSelector(
      (state: any) => ({ user: state.auth.user,
          cards: state.home.cards,
          error: state.home.error,
          loading: state.home.actionsLoading }),
      shallowEqual
  );

  useEffect(() => {
      dispatch(actions.fetchData('bills', `?type=${data?.value || data.name}`)).then((res: any) => {
          // console.log(res);
          var groupedData: any = {};
      
          if (res.data && res.data.length > 0) {
              setAll(res.data);
              res.data.forEach((el: any) => {
                  const billerCode = !first_level.includes(data.name) ? el.name : el.biller_name;
                  if (!groupedData[billerCode]) {
                  groupedData[billerCode] = [];
                  }
                  groupedData[billerCode].push({
                      label: el.biller_name,
                      value: el.biller_name,//item_code
                  });
              });
          }
      
          const formattedData = Object.entries(groupedData).map(([billerCode, options]) => ({
            label: billerCode,
            value: billerCode,
          }));
      
          set_type_data(formattedData);
      });
  }, [dispatch])

  const [type_name, setTypeName] = useState<any>(``)

  const [type, setType] = useState<any>(``)
  const [customer, setCustomer] = useState(``)


  async function submit() {
      

            
      var body1 : any = {};
      body1['customer'] = customer;
      body1['biller_code'] = biller_code;
      body1['item_code'] = item_code;

      var body : any = {};
      body['amount'] = Number(parseFloat(sending_amount.replace(/,/g, '')));
      body['type'] = type;
      body['customer'] = customer;
      
      // console.log(body); return;
      
      // dispatch(actions.addData('validate_bills', body1)).then((res) => {
      //     console.log(res);
          dispatch(actions.addData('pay_bills', body)).then((res: any) => {
              // console.log(res);
              if (res) {
                  navigate(-1)
              }
          });
      // });
  }

  useEffect(() => {
      if (type_name) {
        // Filter the data based on the selected biller_code (type)
        const selectedData = allData.filter((el: any) => el.name === type_name);
    
        // Map the selected data to options for the next select input
        const options = selectedData.map((el: any) => ({
          label: el.biller_name,
          value: el.biller_name,
        }));
    
        set_type_single__data(options);
      }
    }, [type_name]);


    useEffect(() => {
      if (type) {
          // Set the amount based on the selected item
          const selectedData: any = allData.find((el: any) => el.biller_name === type);
          if (selectedData?.amount) {
              set_dis(true)
          }
          const selectedAmount = selectedData ? selectedData.amount : 0;
          setSending(selectedAmount.toString());
          set_code(selectedData?.biller_code);
          set_item(selectedData?.item_code)
          
        }
    }, [type]);




  return (
    <>
      <Appheadertwo handleClick={handleClick} label={'Buy ' + data?.name || 'Item'} />
      
      <SelectField 
        options = {type_data}
        value = {type_name}
        onChange={(e: any) => {
            // setS(e.target.value)
            setTypeName(e.target.value);
            if (first_level.includes(data.name)) {
                setType(e.target.value);
            }
        }}
        type='bills' label='Select Type' placeholder = "Select Type" />

        
        {
        !first_level.includes(data.name) &&

        <SelectField 
            options = {type_single_data}
            value = {type}
            onChange={(e: any) => {
                setType(e.target.value);
            }}
            type='bills' label='Select Type' />
        }



        <InputField
        value = {sending_amount}
        onChange = {(e: any) => {
            if (!e.target.value) {
                e.target.value = '0';
            }
            setSending(Number(parseFloat(e.target.value.replace(/,/g, ''))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
        }}
        label='Amount (NGN)'  type='number'  />



        <InputField
        value = {customer}
        onChange = {(e: any) => {
            setCustomer(e.target.value);
        }}
        label={data.name === 'airtime' ? 'Phone Number' :`Reference Number`}  type='text'  />



        <AuthBtn 
        label='Continue' 
        loading = {loading} 
        disabled = {!biller_code || sending_amount === '' || sending_amount === '0' || type === '' || customer === ''}  
        handleClick={() => submit()}
    />
    </>
  );
}

export default SingleBill;
