import React, {useEffect, useState} from "react";
// import "./Transaction.css";
import { useNavigate } from "react-router-dom";
import InputField from "../../../../components/InputField/InputField";
import SelectField from "../../../../components/InputField/SelectField";
import AuthBtn from "../../../../components/AuthBtn/AuthBtn";

import { shallowEqual, useDispatch, useSelector } from "react-redux";

function NG(props: any) {
  let navigate = useNavigate();
    // var t = props.t;        
    const [bank, setBank] = useState<any>(null);
    // const [account_number, setAccount] = useState('');
   

    const {  listLoading, actionsLoading, banks, } = useSelector(
        (state: any) => ({ 
            banks: state.home.banks,
            listLoading: state.home.listLoading,
            actionsLoading: state.home.actionsLoading
        }),
        shallowEqual
    );


  return (
    <div>

      <SelectField 
        options = {banks}
        value = {bank}
        onChange={(e) => {
          // console.log(e.target);
          setBank(e.target.value);
        }}
        type='bank' label='Bank Type'  placeholder="Select Bank Type"/>

        <InputField
        value = {props.account_number}
        onBlur = {() => props.getBankName(props.account_number, bank)}
        onChange = {(e) => props.set_account_number(e.target.value)}
        label='Account Number'  type='number'  />

        <InputField 
        type = "text"
        disabled = {true}
        value = {props.bank_recipient_info ? props.bank_recipient_info.details.account_name : ''}
        label='Receiver’s Name' />

        <InputField type='text' 
            onChange = {(e:any) => props.set_info(e.target.value)}
            value = {props.info}
            label='Transaction Remarks' />
        


        <AuthBtn 
            label='Continue' 
            loading = {actionsLoading || listLoading}
            disabled = {
                !bank || !props.bank_recipient_info || props.account_number === '' || props.info === ''
            }
            handleClick={() => props.proceed()}
        />
 
    </div>
  );
}

export default NG;
