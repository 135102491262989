
export function _calculateAge(birthday) { // birthday is a date
  var ageDifMs = Date.now() - birthday;
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export function toFeet(n) {
  var realFeet = ((n*0.393700) / 12);
  var feet = Math.floor(realFeet);
  var inches = Math.round((realFeet - feet) * 12);
  return feet + "'" + inches + '\"';
}

export function validateEmail(text){
    if (!text) {
        return;
    }
    if (text.length < 3) {
        return false;
    }
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ ;
    if(reg.test(text) === false)
    {
        return false;
    } else {
        return true;
    }
    }


export function validatePhone(text) {
    
    return text.match(/\d/g).length==10 || text.match(/\d/g).length==11;
}


export function getFormattedDate(oldDate) {
    
    var tempDate = new Date(oldDate);
    var oldTime = Math.round(tempDate.getTime() / 1000);
    var shownDate = '';
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                      
    var year = tempDate.getFullYear(); 
    var month   = tempDate.getMonth();
    var day     = tempDate.getDate();                 
    shownDate = months[month] + ' ' + day + ', ' + year;    
             
    return shownDate;
 }

export function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return mDisplay + sDisplay; 
}

export function validateBVN(text) {
    if(text.length != 11) { 
        return false;
    } else {
        return true
    } 
}

export function randomString(length = 10, chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ") {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
}

export function showCurrency(type) {
    if (type == "naira") {
        return "₦";
    } else if (type == "dollar") {
        return "$";
    } else if (type == "pound") {
        return "£";
    } else if (type == "euro") {
        return "€";
    } else {
        return "₦";
    }
}


export function no_without_commas(str_no) {
    var stringWithCommas = str_no;
    var numberWithoutCommas = parseInt(stringWithCommas.replace(/,/g, ""));
    return numberWithoutCommas;
}

export function returnCountry(name) {
    // console.log(name)
    if (name == "ngn") {
        return "ng";
    } else if (name == "usd") {
        return "us";
    } else if (name == "cad") {
        return "ca";
    } else if (name == "jmd") {
        return "jm";
    } else {
        return "vc";
    }
}

export function returnCurrency(name) {
    if (name == "ng") {
        return "ngn";
    } else if (name == "us") {
        return "usd";
    } else if (name == "ca") {
        return "cad";
    } else if (name == "jm") {
        return "jmd";
    } else {
        return "xcd";
    }
}

export function symbol (c) {
    var c = c ? c.toLowerCase() : '';
    if (c === 'usd') {
        return '$'
    } else if (c === 'ngn') {
        return '₦';
    } else if (c === 'cad') {
        return '$';
    } else if (c === 'jmd') {
        return '$';
    } else if (c === 'xcd') {
        return '$';
    }
    return '$';
}


export function showC2(name) {
    if (name == "naira") {
        return "NGN";
    } else if (name == "dollar") {
        return "USD";
    } else if (name == "euro") {
        return "EUR";
    } else if (name == "pound") {
        return "GBP";
    } else {
        return "NGN";
    }
}


export function number_format (number, decimals, dec_point, thousands_sep) {
    // Strip all characters but numerical ones.
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
    
}

export function money(amount, currency) {
    const formattedValue = amount.toLocaleString(undefined, {
        // style: 'currency',
        // currency: currency.toUpperCase(),
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return formattedValue;
}
  


export function roundNumber(num, scale = 2) {
    if(!("" + num).includes("e")) {
      return +(Math.round(num + "e+" + scale)  + "e-" + scale);
    } else {
      var arr = ("" + num).split("e");
      var sig = ""
      if(+arr[1] + scale > 0) {
        sig = "+";
      }
      return +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
    }
}



export function cc_format(value) {
    var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
    var matches = v.match(/\d{4,16}/g);
    var match = matches && matches[0] || ''
    var parts = []
    var i;
    var len;
  
    for (i=0, len=match.length; i<len; i+=4) {
        parts.push(match.substring(i, i+4))
    }
  
    if (parts.length) {
        return parts.join(' ')
    } else {
        return value
    }
  }
  
  
