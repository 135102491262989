import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Home.css";
import addbtn from "../../../../assets/addbutton.svg";
import { questionmark, searchicon, swapicon, warnicon } from "../../../../konstant";
import InputField from "../../../../components/InputField/InputField";
import AuthBtn from "../../../../components/AuthBtn/AuthBtn";

import TransList from "../../../../components/TransList";
import Applayout from "../../../../layouts/Applayout/Applayout";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
// import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import * as functions from "../../../globals/functions";

import * as actions from "../_redux/home/Actions";
// import CurrencyCard from "../../../../components/CurrencyCard/CurrencyCard";
import InputCurrency from "../../../../components/InputCurrency/InputCurrency";
import CurrencyCard from "../../../../components/CurrencyCard/CurrencyCard";

function Home() {
  const location = useLocation();
  let navigate: any = useNavigate();

  const { user, error, wallet, trans, listLoading, loading } = useSelector(
    (state: any) => ({
      user: state.auth.user.user,
      error: state.home.error,
      wallet: state.home.wallet,
      trans: state.home.trans,
      listLoading: state.home.listLoading,
      loading: state.home.listLoading || state.home.actionsLoading
        
    }),
    shallowEqual
  );


  const dispatch = useDispatch<any>();

  const [index, setIndex] = useState(0);

  const [selected_wallet, setWallet] = useState<any>(null);

  const [currencies, setCurrencies] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState<any>(null);


  const [init_country, setinitialCo] = useState(user?.country?.toLowerCase() || 'us');
  const [rec_country, setRecCountry] = useState(user?.country ? user?.country.toLowerCase() : 'us');

  const [sending_amount, setSending] = useState('10');
  const [rec_amount, setRec] = useState('');
  
  const [rate_sending] = useState(functions.returnCurrency(user?.country ? user?.country : 'us').toUpperCase());
  const [rate_rec, set_rate_rec] = useState(functions.returnCurrency(user?.country ? user?.country : 'us').toUpperCase());


  const [rate, set_rate_amount] = useState('');
  const [rate_data, setRate] = useState<any>(null);
  

   useEffect(() => {

        if (sending_amount && init_country && rec_country) {
            getR();
        }

    }, [init_country, rec_country])

    function getR() {
        setRate(null);
        var body: any = {};
        body['amount'] = Number(parseFloat(sending_amount.replace(/,/g, '')));
        body['type'] = 'deposit';
        body['sending_currency'] = functions.returnCurrency(init_country);
        body['rec_currency'] = functions.returnCurrency(rec_country);
        
        
        dispatch(actions.addData('charge', body)).then((res: any) => {
            // console.log(res);
            if (res.success) {
                setRate(res.success);
                // setRec(res.success?.convertedAmount);
            }
        }).catch((error: any) => {
            console.log("eer", error)
        });
    }



  useEffect(() => {
    var ignore;

    async function fetchData() {
      ignore = false;
      if (!ignore) {
        dispatch(actions.fetchData("wallet")).then((data: any) => {
          if (data && data.length > 0) {
            setWallet(data[0]);
          } else {
            setWallet(null);
          }
        });
        dispatch(actions.fetchData("config"));
        dispatch(actions.fetchData("transactions", "?limit=10"));
      }
    }

    fetchData();

    return () => {
      ignore = true;
      console.log("unsubscribe ", ignore);
    };
  }, []);

  useEffect(() => {
    // console.log('walletCurrencies', wallet)

    if (wallet && wallet.length > 0) {
      const walletCurrencies = wallet.map((item: any) => ({
        label: item.currency.toUpperCase(),
        value: item.currency,
      }));
      console.log("walletCurrencies", walletCurrencies);
      setSelectedCurrency(wallet[0].currency);
      // Set the currencies state with the fetched data
      setCurrencies(walletCurrencies);
    }
  }, [wallet]);

 useEffect(() => {
  // alert('chaaa')
    if (selectedCurrency && wallet) {
      // console.log(selectedCurrency);
        const index = wallet.findIndex((obj : any) => obj.currency.toLowerCase() === selectedCurrency.toLowerCase());
        if (index !== -1) {
            setWallet(wallet[index])
        }
          
    }
  }, [selectedCurrency, wallet])

    



  const [activebtn, setActivebtn] = useState("Send Money");
  const secbtn = [
    {
      id: 1,
      title: "Send Money",
    },
    {
      id: 2,
      title: "Transactions",
    },
  ];

  const transaclit = [
    {
      id: 1,
      name: "Emeka Ezeagu",
      status: "Successful",
    },
    {
      id: 2,
      name: "Olufemi Adetiwa",
      status: "Successful",
    },
    {
      id: 3,
      name: "Olumide Adetiwa",
      status: "Failed",
    },
  ];

  const handleClick = () => {
    // alert("it seems you want to bank with us.");
  };
  return (
    <>
      <div className='homecnt' style = {{marginBottom: 30}}>
        <div className='homecnt-top'>
          <button className='homecnt-topbtn' onClick={() => navigate('/home/fund')}>
            <label>Add Wallet </label>
            <img src={addbtn} alt='' />
          </button>
        </div>
        <div className='homecntsec1'>
          <h2 className='homecntsec1title'>Hi {user?.first_name}!</h2>
        </div>
       
          <div className='homecntsec2'>
            <div className='homecntsec2left'>
              <p className='homecntsec2title'>Account Balance</p>
              <h1 className='homecntsec2amt'>
                {!selected_wallet
                  ? "..."
                  : selected_wallet.length === 0
                  ? "No wallet"
                  : `${functions.symbol(
                      selected_wallet.currency
                    )} ${functions.money(
                      selected_wallet.balance,
                      selected_wallet.currency
                    )}`}
              </h1>
            </div>
            <CurrencyCard
              currencies={currencies} onChange={(val: any) => setSelectedCurrency(val)} value={selectedCurrency} placeholder="Select wallet"
              />
          </div>

      

          <div className='homecntsec3'>
          <div className='homecntsec3nav'> 
          {secbtn.map((secbt, index) => (
            <button
              className={
                activebtn === secbt.title
                  ? "homecntbtnactive"
                  : "homecntbtnnotactive"
              }
              key={index}
              onClick={() => setActivebtn(secbt.title)}
            >
              {secbt.title}
            </button>
          ))}
        </div>
          {activebtn === "Send Money" && (
            <>
              {!user?.verified && (
                <div className='homesndmoneytop'>
                  <div className='hsndmtleft'>
                    <div className='hmsndmtlefttop'>
                      <div className='hmsndmtwarn'>{questionmark}</div>
                      <div className='hmsndmtsa'>
                        <p className='hmsndmttitle'>
                          Verify your identity, {user?.first_name || "OOO"}!
                        </p>
                        <p className='hmsndmtparagraph'>
                          We're required by law to verify your identity to
                          increase your transaction limit.
                        </p>
                      </div>
                    </div>
                  </div>{" "}
                  <div className='hmsndmtrighttop'>
                    <button className='hmsndverify'>Verify</button>
                  </div>
                </div>
              )}


              <InputCurrency 
                label="You Send" 
                header_label = "Select Sending Currency"
                onBlurFunction = {() => getR()}
                value = {sending_amount}
                disabled = {false}
                chooseCurrency = {(d: any) => {
                  setinitialCo(d);
                }}
                country = {init_country}
                onChange = {(c: any) => {
                  // console.log(c)
                  // console.log(num)
                  if (!c) {
                      c = '0';
                  }
                  var num = Number(parseFloat(c.replace(/,/g, ''))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  setSending(num);
                }}
              />
              {/* <div className="thesawap">
                {swapicon}
               <button className="thesawapbtn">Swap</button>
              </div> */}
              <InputCurrency 
                  label="Receiver Gets"
                  header_label = "Select Receiving Currency"
                  onBlurFunction = {() => getR()}
                  disabled = {true}
                  country = {rec_country}
                  value = {rate_data?.convertedAmount.toFixed(2).toString() || '0'}
                  chooseCurrency = {(d: any) => {
                    setRecCountry(d);
                  }}
                  onChange = {(c: any) => console.log('')}
              />

              {rate_data &&
              <div className="thesawapp">
                <p className="thesawaprate">Rate</p>
                <p className="thesawapprice">
                  {`1 ${rate_data?.your_currency.toUpperCase()}`} {` = ${Number(rate_data?.exchange).toFixed(4)} ${rate_data?.rec_currency.toLocaleUpperCase()}`}
                </p>
                {warnicon}
              </div>}
              <AuthBtn 
              label='Continue' 
              loading = {loading}
              disabled = {Number(sending_amount) < 1 || !selected_wallet || !rate_data}
              handleClick={() => navigate(`/home/send`, {
                  state: {
                    amount_sending: sending_amount,
                    amount_rec: rec_amount,
                    init_country,
                    rec_country,
                    rate_data,
                  }
              })}
  
              />
            </>
          )}
          {activebtn === "Transactions" && (
            <>
              {/* <div className='hometransactiontop'>
              <input
                type='text'
                placeholder='Find Transaction'
                className='htrancinput'
              />
              <div className='htrancsearch'>{searchicon}</div>
            </div> */}

              <div className='hometranclist'>
                {trans &&
                  trans
                    .slice(0, 10)
                    .map((trans: any, index: number) => (
                      <TransList key={index} data={trans} />
                    ))}
              </div>
            </>
          )}
        </div> 
        </div> 
      <Applayout linkaddress={location.pathname} />
    </>
  );
}

export default Home;
