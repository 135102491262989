import React from 'react';
import "./styles.css";
import { shallowEqual,  useSelector } from "react-redux";
import moment from "moment";
import * as functions from "../../app/globals/functions";
import { useNavigate } from "react-router-dom";


const TransList = (props) => {
    
    var data = props.data;
    let navigate = useNavigate();
  
    const { user } = useSelector(
        (state) => ({ user: state.auth.user}),
        shallowEqual
    );

    // const to_me = user._id === data.user_id;
    const to_me = user._id === data?.recipient?._id;
   

    function getTitle(d) {
        

        
        if (to_me) {
            if (d.type === 'bill') {
                return d?.remark || 'Bills Payment'
            }
            if (d.type === 'bank_transfer') {
                return 'Bank Transfer'
            }
            if (d.type === 'wallet') {
                return 'Wallet Funded';
            } else {
                return 'Credit Alert';
            }
        } else {
            return 'Transfer Sent';
        }
            
        
    }


    function getD(date) {
        if (moment().format('L') !== moment(date).format('L')) {
            return `${moment(date).format('ll')} • ${moment(date).format('LT')}`;
        } else {
            return `${moment(date).format('LT')}`;
        }
    }




    function getCur() {
        if (data.type === 'bill') {
            return data.currency;
        }
        if (data.type === 'bank_transfer') {
            return data.currency;
        }
        if (to_me) {
            return data.currency_rec;
        }
        return data.currency;
    }

    function getAm() {
        if (data.type === 'bill') {
            return data.amount;
        }
        if (data.type === 'bank_transfer') {
            return data.amount;
        }
        if (to_me) {
            return data.amount_receive;
        }
        return data.amount;
    }



  return (
    <div
    className='hmtransitem'
    onClick={() => navigate(`/transaction/${data._id}`)}
  >
    <div className='hmtransitemleft'>
      <div
        className={
          data.status === "success" ? "transuc" : "transfail"
        }
      ></div>
      <div className='hmnamestatus'>
        <p className='hmname'>{getTitle(data)}</p>
        <p
          className={
            data.status === "success" ? "trasuc" : "trafail"
          }
        >
          {data.status}
        </p>
      </div>
    </div>
    <div className='hmtransitemright'>
      <p className='hmrprice'>{`${functions.symbol(getCur())} ${functions.number_format(getAm())}`}</p>
      <div className='hmrpricedol'>{getD(data.createdAt)}</div>
    </div>
  </div>
  );
};

export default TransList;