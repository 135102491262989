import React, { useState, ReactNode } from "react";
import "./InputCurrency.css";
import cadflag from "../../assets/cadflag.svg";
import gbpflag from "../../assets/gbpflag.svg";
import usdflag from "../../assets/usdflag.svg";
import Appheaderone from "../Appheaderone/Appheaderone";
import { arrowdownicon } from "../../konstant";
import CurrencyFlag from 'react-currency-flags';
import ReactCountryFlag from "react-country-flag"

interface Props {
  label: string;
  header_label: string;
  onBlurFunction: any;
  value: any;
  disabled: boolean;
  chooseCurrency: any;
  country: string;
  onChange: any;

}

const InputCurrency: React.FC<Props> = ({ label, header_label, country, disabled, value, onBlurFunction,  chooseCurrency, onChange }) => {
  const [currency, setCurrency] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const currencylist = [
    
    {
      name: "United States",
      currency: "USD",
      countrycode: "us",
      flag: usdflag,
    },
    {
      name: "Nigeria",
      currency: "NGN",
      countrycode: "ng",
      flag: gbpflag,
    },
    {
      name: "United Kingdom",
      currency: "GBP",
      countrycode: "gb",
      flag: gbpflag,
    },
    {
      name: "Canada",
      currency: "CAD",
      countrycode: "ca",
      flag: cadflag,
    },
  ];
  return (
    <>
      <label className='inputlabel'>{label}</label>
      <div className='inputcurcnt'>
        <input 
        // type='number'
        //  placeholder="0.00"
        value = {value} disabled = {disabled} className='inputcurrencyinput'
         onChange={(e: any) => onChange(e.target.value.trim())}
         onBlur={() => onBlurFunction()}
        />
        <div className='inputcurrencycardcnt' onClick={() => setCurrency(true)}>
          {/* {currencylist.slice(0, 1).map((crlst, index) => ( */}
            <div className='inryimage'>
              {/* // <img src={crlst.flag} alt='' />  */}
              <ReactCountryFlag countryCode={country.toUpperCase()} /> 
              {country.toUpperCase()}{" "}
              {arrowdownicon}
            </div>
          {/* ))} */}
        </div>
      </div>
      {currency && (
        <div className='inputcurrencylist'>
          <Appheaderone
            handleClick={() => setCurrency(false)}
            label={header_label || 'Select Currency'}
          />

          <div className='inputcurrencylistcnt'>
            {currencylist.map((crlst, index) => (
              <div className='crclstcnt' key={index}>
                <div 
                onClick={() => {
                  chooseCurrency(crlst?.countrycode)
                  setCurrency(false);
                }}
                className='crclstcntleft'>
                  {/* <img src={crlst.flag} alt='' className='crclsflag' /> */}
                  <CurrencyFlag currency={crlst?.currency.toUpperCase()} size="md" className='crclsflag'/>
                  <p className='crclsname'>{crlst.name}</p>
                </div>
                <p className='crclstcntright'>{crlst.currency}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default InputCurrency;
