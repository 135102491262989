import React, {useEffect, useState} from "react";
// import "./Transaction.css";
import { useNavigate } from "react-router-dom";
import Appheadertwo from "../../../../components/Appheadertwo/Appheadertwo";
import InputField from "../../../../components/InputField/InputField";
import SelectField from "../../../../components/InputField/SelectField";
import AuthBtn from "../../../../components/AuthBtn/AuthBtn";

import { copyicon, downloadicon } from "../../../../konstant";
import { useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../_redux/home/Actions";
import * as functions from "../../../globals/functions";
import moment from "moment";
import { toast } from 'react-toastify';
import {useLocation} from 'react-router-dom';
import InputCurrency from "../../../../components/InputCurrency/InputCurrency";


import Tespay from "./tespay";
import Bank from "./bank";

function Fund(props: any) {
  let navigate = useNavigate();

    const dispatch = useDispatch<any>();
    const handleClick = () => {
        navigate(-1);
    };

    // const data = props.route.params;
 
    const { user, loading, cards, error } = useSelector(
        (state: any) => ({ user: state.auth.user,
            cards: state.home.cards,
            error: state.home.error,
            loading: state.home.actionsLoading }),
        shallowEqual
    );


    // const handler = useCallback(debounce(someFunction, 2000), []);

    // const onChange = (event) => {
    //     // perform any event related action here

    //     handler();
    // };

    const [init_country, setCountry] = useState(user.country ? user.country.toLowerCase() : 'us');
    // const [rec_country, setRecCountry] = useState(user.country ? user.country.toLowerCase() : 'us');
  
    const [selected_card, setCard] = useState<any>(null);
    const [selected_card_id, setCardId] = useState<any>(null);
    const [rate_data, setRate] = useState<any>(null);
    const [text, setText] = useState(null);
    const [sending_amount, setSending] = useState<any>('10');
    const [loading2, setLoading] = useState(false);
  
    useEffect(() => {
        setLoading(true);
        dispatch(actions.fetchData('cards', ``)).then((res: any) => {
            setCard(res[0]);
            setCardId(res[0]._id);
            setLoading(false);
        }); 
    }, [dispatch])




    useEffect(() => {

        if (sending_amount && init_country && selected_card) {
            getR();
        }

    }, [init_country, selected_card])

    function getR() {
        setRate(null);
        var body: any = {};
        body['amount'] = Number(parseFloat(sending_amount.replace(/,/g, '')));
        body['type'] = 'wallet';
        body['sending_currency'] = selected_card?.currency;
        body['rec_currency'] = functions.returnCurrency(init_country);
        
        
        dispatch(actions.addData('charge', body)).then((res: any) => {
            if (res.success) {
                setRate(res.success);
            }
        }).catch((error: any) => {
            console.log("eer", error)
        });
    }
  
    async function submit() {
        
                         

              


        var body1 : any= {};
        body1['amount'] = Number(parseFloat(sending_amount.replace(/,/g, '')));
        body1['type'] = 'wallet';
        body1['sending_currency'] = selected_card?.currency;
        body1['rec_currency'] = functions.returnCurrency(init_country);
        

        dispatch(actions.addData('charge', body1)).then((res: any) => {
            if (res.success) {
                setRate(res.success);
                

                var body : any= {};
                body['amount'] = Number(parseFloat(sending_amount.replace(/,/g, '')));
                body['amount_to_charge'] = res.success.convertedAmount;
                body['rec_currency'] = res.success.rec_currency;
                body['currency'] = res.success.your_currency;
                body['card_id'] = selected_card._id;
                body['exchange'] = res.success.exchange;
                body['extra_charges'] = res.success.extra_charges;
                
            
                
                dispatch(actions.addData('fund', body)).then((res : any) => {
                    // console.log("casdsdsd", res);
                    if (res && res.success) {
                        toast.success('Successfully added to your wallet');
                        navigate('/');
                        // showError('Successfully added to your wallet', 'success');
                        // props.navigation.navigate('Confirm', {data: res.success});
                    } 
                });
            }
        }).catch((error : any) => {
            console.log("eer", error)
        });
        
        
    }



  return (
    <div>
      <Appheadertwo handleClick={handleClick} label="Fund Wallet" />
      
        
      <InputCurrency 
            label="Enter Deposit Amount" 
            header_label = "Amount to deposit"
            onBlurFunction = {() => getR()}
            value = {sending_amount}
            disabled = {false}
            chooseCurrency = {(d: any) => {
                setCountry(d);
            }}
            country = {init_country}
            onChange = {(c: any) => {
                if (!c) {
                    c = '0';
                }
                var num = Number(parseFloat(c.replace(/,/g, ''))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                setSending(num);
            }}
        />
        

        {rate_data &&
            <p className="thesawapprice" style = {{
                marginBottom: 20,
                marginTop: 20,
                paddingLeft: 20,
            }}>{`You will be charged ${rate_data.your_currency.toUpperCase()} ${functions.number_format(rate_data.convertedAmount)} for this transaction`}</p>
        }

        <p className="hmsndmttitle" style = {{
                marginBottom: 20,
                marginTop: 20,
                // fontSize: 12,
                paddingLeft: 20,
            }}>{`Payment Options`}</p>


        <SelectField 
            options = {cards}
            value = {selected_card_id}
            onChange={(e) => {
                setCard(cards.find((obj: any) => obj._id === e.target.value));
                setCardId(e.target.value)
            }}
            type='cards' label='Card'  
            // placeholder="Select Card"
            />

      

        <AuthBtn 
            label='Done' 
            loading = {loading} 
            disabled = {!rate_data || !selected_card ||sending_amount === ''} 
            handleClick={() => submit()}
        />
 
    </div>
  );
}

export default Fund;
