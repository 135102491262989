import { FC } from "react";
import { Navigate, Routes, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";

import { Logout, AuthPage } from "../modules/auth";
import { RootState } from "../../setup";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import Login from "../modules/auth/components/Signin/Signin";
import Register from "../modules/auth/components/Signup/Signup";
import Verify from "../modules/auth/components/Verify";
import Pin from "../modules/auth/components/Pin";

import Home from "../modules/home/main/Home"
import Details from "../modules/home/main/details"
import Payment from "../modules/home/main/payment"
import Confirm from "../modules/home/main/confirm"
import TransactionSingle from "../modules/home/single"
import Bills from "../modules/bills/Bills";
import SingleBill from "../modules/bills/Single";
import Actions from "../modules/action/Actionpage";
import Crypto from "../modules/crypto/Cryptopage";
import Fund from "../modules/home/main/fund";

// import ErrorPage from "./error-page";

const App_Routes: FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  var isAuthorized = useSelector<RootState>(
    // ({ auth }) => auth.user?.user?.verification_status === "approved",
    ({ auth }) => auth.user?.user,
    shallowEqual
  );

  return (
    <Routes>
      {!isAuthorized ? (
        <>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/verify" element={<Verify />} />
          <Route path="/pin" element={<Pin />} />
          <Route path="*" element={<Navigate to="/login" />} />
          </>
      ) : (
        <Route path="/login" element={<Navigate to="/" />} />
      )}
      <Route path="/logout" element={<Logout />} />
      <Route path="/error" element={<Logout />} />

      {!isAuthorized ? (
        <Route path="/" element={<Navigate to="/login" />} />
      ) : (
        <>
          {/* <Route path="/" element={<Navigate to="/" />} /> */}
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/home/send" element={<Details />} />
          <Route path="/home/fund" element={<Fund />} />
          <Route path="/home/payment" element={<Payment />} />
          <Route path="/home/success" element={<Confirm />} />
          <Route path="/transaction/:id" element={<TransactionSingle />} />
          
          <Route path="/crypto" element={<Crypto />} />
          <Route path="/bills" element={<Bills />} />
          <Route path="/bills/pay" element={<SingleBill />} />
          <Route path="/action" element={<Actions />} />
          
          <Route path="*" element={<Navigate to="/home" />} />
        </>
      )}
    </Routes>
  );
};

export { App_Routes };
