import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import "./Signin.css";
import InputField from "../../../../../components/InputField/InputField";
import AuthBtn from "../../../../../components/AuthBtn/AuthBtn";
import Alreadysign from "../../../../../components/Alreadysign/Alreadysign";
import prof from "../../../../../assets/prof.png";

import * as functions from '../../../../globals/functions';
import { toast } from 'react-toastify';
import * as auth from '../../_redux/authRedux'
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { login, getUserByToken } from '../../_redux/authCrud'




function Signin() {
  let navigate = useNavigate();
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState('')
  const [email, set_email] = useState('')
  const [password, set_password] = useState('')
  


  const {
    user,
  } = useSelector(
    (state: any) => ({
      user: state.auth?.user?.user,
    }),
    shallowEqual
  );


  useEffect(() => {
    // dispatch(auth.actions.logout());
  }, [])

  const handleClick = () => {
    // navigate("/set-transfer-pin");


    if (email === '' || password === '') {
      toast.error('Enter all inputs!');
      return;
    }

    // console.log(password, email); return;

    if (!functions.validateEmail(email)) {
      toast.error('Enter a valid email address!');
      return;
    }

    setLoading(true);
    login(email, password, 'customer')
    .then((response) => {
        var data = response.data;
        console.log(data);
        setLoading(false);
        if (data.error) {
          // setStatus(data.error)
          toast.error(data.error)
        } else {
          // props.login(data.user_token);
          dispatch(auth.actions.login(data.user_token));
      
        }
      })
      .catch((error) => {
        console.log(error)
        setLoading(false);
        toast.error(`Error: ${error?.response?.data?.message || 'Network error occured logging in'}`)
      });

  
  };

  const handleAuthClick = () => {
   navigate('/register')
  };
  return (
    <div className='signupcnt'>
      <div className='signinalert'>
        <img src={prof} alt='' />
        <h2>{`Welcome back, ${user?.first_name || ''}`}</h2>
        <p>Please enter your email address</p>
      </div>
      <InputField 
      type="email" required={true}
      onChange={(e: any) => set_email(e.target.value.trim())}
      label="Email address" placeholder="e.g. johndoe@email.com" 
      />


      <InputField 
      type="password" required={true}
      onChange={(e: any) => set_password(e.target.value.trim())}
      label="Password" placeholder="" 
      />

      <AuthBtn label='Log into your account'
      loading = {loading}
      handleClick={handleClick} />
      <Alreadysign
        name='New User?'
        label='Sign up'
        handleAuthClick={handleAuthClick}
      />
      <p className='registeragree'>
        © 2023 TesPay | All Rights Reserved <br />
        v2.0.1b
      </p>
    </div>
  );
}

export default Signin;
