import React from "react";
import { Link } from "react-router-dom";
import "./Applayout.css";
import {
  actionicon,
  billsIconActive,
  billsIconNotActive,
  cryptoIconActive,
  cryptoIconNotActive,
  homeIconActive,
  homeIconNotActive,
  profileIconActive,
  profileIconNotActive,
} from "../../konstant";

interface Props {
  linkaddress: string;
}

const Applayout: React.FC<Props> = ({ linkaddress }) => {
  return (
    <div className='applayoutcnt'>
      <div className='navbaritemscnt'>
        <Link
          to='/home'
          className={`navbaritemscnt-items ${
            linkaddress === "/home" && "nbiciactive"
          }`}
        >
          {linkaddress === "/home" ? (
            <>
              {homeIconActive}
              <label>Home</label>
            </>
          ) : (
            <>
              {homeIconNotActive}
              <label>Home</label>
            </>
          )}
        </Link>

        <Link
          to='/bills'
          className={`navbaritemscnt-items ${
            linkaddress === "/bills" && "nbiciactive"
          }`}
        >
          {linkaddress === "/bills" ? (
            <>
              {billsIconActive}
              <label>Bills</label>
            </>
          ) : (
            <>
              {billsIconNotActive}
              <label>Bills</label>
            </>
          )}
        </Link>

        <Link
          to='/action'
          className={`navbaritemscnt-items theaction ${
            linkaddress === "/action" && "nbiciactive"
          }`}
        >
          {linkaddress === "/action" ? (
            <>
              {actionicon}
              <label>Action</label>
            </>
          ) : (
            <>
              {actionicon}
              <label>Action</label>
            </>
          )}
        </Link>
        <Link
          to='/crypto'
          className={`navbaritemscnt-items ${
            linkaddress === "/crypto" && "nbiciactive"
          }`}
        >
          {linkaddress === "/crypto" ? (
            <>
              {cryptoIconActive}
              <label>Crypto</label>
            </>
          ) : (
            <>
              {cryptoIconNotActive}
              <label>Crypto</label>
            </>
          )}
        </Link>
        <Link
          to='/profileaction'
          className={`navbaritemscnt-items ${
            linkaddress === "/profile" && "nbiciactive"
          }`}
        >
          {linkaddress === "/profile" ? (
            <>
              {profileIconActive}
              <label>Profile</label>
            </>
          ) : (
            <>
              {profileIconNotActive}
              <label>Profile</label>
            </>
          )}
        </Link>
      </div>
    </div>
  );
};

export default Applayout;
