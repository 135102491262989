import * as requestFromServer from "./Crud";
import {homeSlice, callTypes} from "./Slice";
import { toast } from 'react-toastify';

const {actions} = homeSlice;

export const fetchData = (type, queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findData(type, queryParams)
    .then(response => {
      dispatch(actions.stopLoading());
       
      var res = response.data;
      if (res.error) {
        dispatch(actions.catchError({ error: res.error, callType: callTypes.list }));
        return;
      }
      
      if (type === 'user') {
        return res;
      } else if (type === 'wallet') {
        dispatch(actions.walletFetched(res.success));
        return res.success;
     

      } else if (type === 'currency_wallet') {
        return res.success;
      } else if (type === 'bills') {
        return res;
      } else if (type === 'config') {
        dispatch(actions.configFetched(res.success));
      } else if (type === 'transactions') {
        dispatch(actions.transFetched(res.success));
      } else if (type === 'trans') {
        return res;
      } else if (type === 'cards') {
        dispatch(actions.cardsFetched(res.success));
        return res.success;
      } else if (type === 'banks') {
        dispatch(actions.bankFetched(res.success));
        return res.success;
      } else if (type === 'payee') {
        dispatch(actions.payeeFetched(res.success));
      } else if (type === 'crypto') {
        dispatch(actions.cyptoFetched(res));
      }else if (type === 'logs' || type === 'single_trans') {
        return res;
      } else if (type === 'user_wallet') {
        return res;
      }
      // const { totalCount, entities } = response.data;
      // dispatch(actions.dataFetched({ totalCount, entities }));
    })
    .catch(error => {
      // error.clientMessage = "Can't find data";
      console.log("ERRRRR", error)
      toast.error(error?.response?.data?.error || "Server error occured. Please try again!");
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const saveData = (type, data) => dispatch => {
  if (type === 'wallet') {
    dispatch(actions.walletFetched(data));
  } else if (type === 'config') {
    dispatch(actions.configFetched(data));
  } else if (type === 'trans') {
    dispatch(actions.transFetched(data));
  } else if (type === 'cards') {
    dispatch(actions.cardsFetched(data));
  } else if (type === 'banks') {
    dispatch(actions.bankFetched(data));
  } else if (type === 'payee') {
    dispatch(actions.payeeFetched(data));
  } else if (type === 'crypto') {
    dispatch(actions.cyptoFetched(data));
  }else if (type === 'logs') {
    dispatch(actions.logFetched(data));
  }
};

export const fetchCustomer = id => dispatch => {
  if (!id) {
    return dispatch(actions.dataFetched({ dataForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getCustomerById(id)
    .then(response => {
      const data = response.data;
      dispatch(actions.dataFetched({ dataForEdit: data }));
    })
    .catch(error => {
      error.clientMessage = "Can't find data";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteCustomer = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCustomer(id)
    .then(response => {
      dispatch(actions.dataDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete data";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const addData = (type, dataForCreation) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .addData(type, dataForCreation)
    .then(response => {
      var res = response.data;
      if (res.error) {
        dispatch(actions.catchError({ error: res.error, callType: callTypes.action }));
        return;
      }
    
      dispatch(actions.stopLoading());
      return res;
      // const { data } = response.data;
      // dispatch(actions.dataCreated({ data }));
    })
    .catch(error => {
      // console.log("dfewefe", error)
      // error = error?.response?.data?.error || error;
      toast.error(error?.response?.data?.error || "Server error occured. Please try again!");
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateUser = (data) => dispatch => {
  // dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateCustomer(data)
    .then((rs) => {
      // console.log("Hdhjsdcjdcsdcawcecvqefcec", rs);
      // dispatch(actions.dataUpdated({ data }));
    })
    .catch(error => {
      error.clientMessage = "Can't update data";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateCustomersStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForCustomers(ids, status)
    .then(() => {
      dispatch(actions.dataStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update data status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteCustomers = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCustomers(ids)
    .then(() => {
      dispatch(actions.dataDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete data";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
